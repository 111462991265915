<template>
  <div>
    <div class="contantTop flex flex_b">
      <div class="tipTitle">员工管理</div>
      <div class="tipButton flex flex_ju_center flex_center" @click="add">新增</div>
    </div>
    <div class="contant flex flex_row">
      <div class="leftContant">
        <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
      </div>
      <div class="rightContant">
        <el-table :data="tableData" style="width: 100%" header-cell-class-name="tableClass" border>
          <el-table-column align="center" prop="date" label="职位"> </el-table-column>
          <el-table-column align="center" prop="name" label="账号"> </el-table-column>
          <el-table-column align="center" prop="address" label="密码"> </el-table-column>
          <el-table-column align="center" prop="date" label="用户名"> </el-table-column>
          <el-table-column align="center" prop="name" label="入职时间"> </el-table-column>
          <el-table-column align="center" prop="address" label="积分"> </el-table-column>
          <el-table-column align="center" prop="address" label="操作">
            <template slot-scope="scope">
              <div class="flex flex_row flex_ju_center">
                <div class="color_font">调岗</div>
                <div class="border_center">编辑</div>
                <div class="color_font" @click="delData">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page flex flex_end">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="100"
            layout="prev, pager, next, jumper"
            :total="1000"
            prev-text="上一页"
            next-text="下一页"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      currentPage: 1,
      data: [
        {
          label: '一级 1',
          children: [
            {
              label: '二级 1-1',
              children: [
                {
                  label: '三级 1-1-1',
                },
              ],
            },
          ],
        },
        {
          label: '一级 2',
          children: [
            {
              label: '二级 2-1',
              children: [
                {
                  label: '三级 2-1-1',
                },
              ],
            },
            {
              label: '二级 2-2',
              children: [
                {
                  label: '三级 2-2-1',
                },
              ],
            },
          ],
        },
        {
          label: '一级 3',
          children: [
            {
              label: '二级 3-1',
              children: [
                {
                  label: '三级 3-1-1',
                },
              ],
            },
            {
              label: '二级 3-2',
              children: [
                {
                  label: '三级 3-2-1',
                },
              ],
            },
          ],
        },
      ],
      defaultProps: {
        children: 'children',
        label: 'label',
      },

      tableData: [
        {
          date: '2016-05-03',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-02',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-04',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-01',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-08',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-06',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
        {
          date: '2016-05-07',
          name: '王小虎',
          address: '上海市普陀区金沙江路 1518 弄',
        },
      ],
    }
  },
  methods: {
    delData() {
      this.utils.error('hhhh')
    },
    add() {
      this.$router.replace({
        path: '/staff/addStaff'
      })
    },
    handleNodeClick(data) {
      // console.log(data)
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
    },
  },
}
</script>
<style lang="less" scoped>
.contant {
  .leftContant {
    width: 20%;
  }
  .rightContant {
    width: 80%;
  }
  .color_font {
    color: #f39517;
    font-size: 14px;
    cursor: pointer;
  }
}
</style>
